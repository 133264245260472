import { Box, Stack } from '@sparky';
import { styled } from '@sparky/stitches';

export const config = {
  minHeight: {
    initial: '280px',
    sm: '280px',
    md: '460px',
    lg: '640px',
  },
  offSetContentContainerBottom: '$space$24',
};

export const HeroContainer = styled(Box, {
  position: 'relative',
  width: '100%',
  height: 'auto',

  variants: {
    isVariableWidth: {
      true: {
        '@xl': {
          margin: 'auto',
          maxWidth: '1920px',
          width: '90vw',
        },
      },
    },
    isMinMargin: {
      true: {
        '@xl': {
          marginTop: '-$space$24',
        },
      },
    },
  },
});

export const ImageContainer = styled('div', {
  position: 'absolute',
  left: '0',
  top: '0',
  width: '100%',
  height: '100%',
});

export const ContentContainer = styled(Stack, {
  position: 'relative',
  minHeight: config.minHeight.initial,

  '@md': {
    minHeight: config.minHeight.md,
  },

  '@lg': {
    minHeight: config.minHeight.lg,
  },

  variants: {
    hasBottomPlaceholder: {
      true: {
        '@lg': {
          paddingBottom: '$space$24',
        },
      },
    },
    hasRightPlaceholder: {
      true: {
        paddingTop: '0',
      },
      false: {
        paddingTop: '220px', // values are directly from design

        '@md': {
          paddingTop: '416px', // values are directly from design
        },

        '@lg': {
          paddingTop: '0',
        },
      },
    },
    isVariableHeight: {
      true: {
        minHeight: 'unset',
        paddingTop: 'unset !important',
      },
    },
  },
});

export const RightPlaceholderBox = styled(Box, {
  position: 'relative',
  minHeight: config.minHeight.initial,

  '@md': {
    minHeight: config.minHeight.md,
  },

  '@lg': {
    minHeight: 'unset',
  },

  variants: {
    isVariableHeight: {
      true: {
        minHeight: 'unset',
      },
    },
  },
});

HeroContainer.displayName = 'HeroContainer';
ImageContainer.displayName = 'ImageContainer';
