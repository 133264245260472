import { Box } from '@sparky';
import { styled } from '@sparky/stitches';

export const Relative = styled('section', {
  position: 'relative',
  picture: {},
});

export const Background = styled(Box, {
  position: 'absolute',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  zIndex: '-1',
});
