import { FC } from 'react';

import { config, ContentContainer, HeroContainer, RightPlaceholderBox, ImageContainer } from '@custom-components/Hero';
import { Relative } from '@custom-components/Section';
import { Placeholder, mapImage } from '@sitecore/common';
import { HeroRendering } from '@sitecore/types/manual/Hero.types';
import { Bleed, Box, Grid, Image, PageGrid, Stack } from '@sparky';

const Hero: FC<HeroRendering> = ({ fields, params, placeholders }) => {
  const hasBottomPlaceholder = !!placeholders?.['jss-hero-bottom']?.length;
  const hasLeftPlaceholder = !!placeholders?.['jss-hero-left']?.length;
  const hasRightPlaceholder = !!placeholders?.['jss-hero-right']?.length;
  const isVariableHeight = params?.height === 'variable';
  const isVariableWidth = params?.width === 'variable';
  const isMinMargin = params?.minMargin === 'true';

  const imageProps = mapImage(fields?.image);
  return (
    <Box backgroundColor={params?.backgroundColorBottom}>
      <HeroContainer
        backgroundColor={params?.backgroundColor}
        isVariableWidth={isVariableWidth}
        isMinMargin={isMinMargin}>
        {imageProps.src ? (
          <ImageContainer>
            <Image
              alt={imageProps.alt}
              height={{
                initial: config.minHeight.initial,
                sm: config.minHeight.sm,
                md: config.minHeight.md,
                lg: '100%',
              }}
              hasLazyLoad={false}
              objectFit="cover"
              sources={imageProps.sources}
              src={imageProps.src}
              width="100%"
            />
          </ImageContainer>
        ) : null}

        <ContentContainer
          alignY="justify"
          hasBottomPlaceholder={hasBottomPlaceholder}
          hasRightPlaceholder={hasRightPlaceholder}
          isVariableHeight={isVariableHeight}>
          <Stack.Item>
            <PageGrid>
              <PageGrid.Item gridColumn="1/-1">
                <Box
                  paddingTop={{ initial: hasRightPlaceholder ? '8' : isVariableHeight ? '8' : '0', lg: '16' }}
                  paddingBottom={{ initial: isVariableHeight ? '24' : '8', lg: hasBottomPlaceholder ? '8' : '16' }}>
                  <Grid gridTemplateColumns={{ initial: '1fr', lg: '1fr 1fr' }} columnGap={{ lg: '8', xl: '16' }}>
                    {hasLeftPlaceholder && (
                      <Grid.Item
                        order={{
                          initial: 2,
                          lg: 1,
                        }}>
                        <Box paddingTop={{ initial: '2', lg: '0' }} paddingBottom={{ initial: '2', lg: '0' }}>
                          <Placeholder name="jss-hero-left" />
                        </Box>
                      </Grid.Item>
                    )}
                    {hasRightPlaceholder && (
                      <Grid.Item
                        order={{
                          initial: 1,
                          lg: 2,
                        }}>
                        <RightPlaceholderBox
                          paddingTop={{ initial: '2', lg: '0' }}
                          paddingBottom={{ initial: '2', xl: '0' }}>
                          <Placeholder name="jss-hero-right" />
                        </RightPlaceholderBox>
                      </Grid.Item>
                    )}
                  </Grid>
                </Box>
              </PageGrid.Item>
            </PageGrid>
          </Stack.Item>
        </ContentContainer>
      </HeroContainer>
      {hasBottomPlaceholder && (
        <Relative>
          <Bleed top={{ initial: isVariableHeight ? '24' : '0', lg: isVariableHeight ? undefined : '24' }}>
            <PageGrid>
              <PageGrid.Item gridColumn="1/-1">
                <Placeholder name="jss-hero-bottom" />
              </PageGrid.Item>
            </PageGrid>
          </Bleed>
        </Relative>
      )}
    </Box>
  );
};

export default Hero;
